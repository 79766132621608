<script>
import { defineComponent } from 'vue'
import { useViewStore } from './store'
import { SPopover } from '@shein-aidc/sui-popover/mobile'
import PurchaseCouponFace from './widgets/coupon-face.vue'
import PurchaseCouponBannerCountDown from './widgets/count-down.vue'
import PurchaseCouponBannerProgress from './widgets/coupon-progress.vue'
import { 
  sui_icon_doubt_16px_2 as SuiIconDoubt,
  sui_icon_more_right_12px as SuiIconMoreRight,
  sui_icon_selected_16px as SuiIconSelected,
} from '@shein-aidc/icon-vue3'
import { vOnVisible as onVisible } from './directives'
import { SAdapterText } from '@shein-aidc/sui-adapter-text/mobile'

export default defineComponent({
  name: 'PurchaseCouponBannerView',
  directives: {
    onVisible,
  },
  components: {
    SAdapterText,
    SPopover,
    PurchaseCouponFace,
    PurchaseCouponBannerCountDown,
    PurchaseCouponBannerProgress,
    SuiIconDoubt,
    SuiIconMoreRight,
    SuiIconSelected,
  },
  props: {
    languages: {
      type: Object,
      default: () => ({}),
    },
    purchaseCouponInfo: {
      type: Object,
      default: () => ({}),
    },
    usingScene: {
      type: String,
      default: 'homepage', // homepage 首页 | list 列表
    },
  },
  emits: ['click', 'cart-checkout'],
  setup(props, { emit }) {
    return {
      ...useViewStore(props, { emit }),
    }
  },
})
</script>

<template>
  <div
    ref="purchaseCouponBannerViewRef"
    class="purchase-coupon-banner__view-container"
    :class="{
      'homepage-scene': isHomePageScene,
      'list-scene': isListPageScene,
    }"
  >
    <div 
      class="purchase-coupon-banner__view-card"
      :style="bgStyle"
    >
      <!-- 券面信息 START -->
      <div class="purchase-coupon-banner__coupon-face-group">
        <div
          class="purchase-coupon-banner__cover-small"
          :style="[couponBgStyle,cssVariables]"
        >
        </div>
        <div
          v-if="purchaseCouponInfo?.benefitTextColor"
          class="purchase-coupon-banner__coupon-bg"
          :style="[couponBgStyle,cssVariables]"
        >
          <div class="purchase-coupon-banner__coupon-face-info">
            <div
              class="purchase-coupon-banner__coupon-discount"
              :style="{
                color: purchaseCouponInfo?.benefitTextColor
              }"
            >
              <s-adapter-text
                :min-size="10"
                :lines="isListPageScene ? 1 : 2"
                :text="displayInfo.couponTitle"
              />
            </div>
            <s-adapter-text
              class="purchase-coupon-banner__coupon-threshold"
              :min-size="8"
              :text="displayInfo.couponSubTitle"
              :style="{
                color: purchaseCouponInfo.benefitTextColor
              }"
            />
          </div>
        </div>
        <div
          v-else
          class="purchase-coupon-banner__coupon-face-info"
        >
          <div
            class="purchase-coupon-banner__coupon-discount"
            :style="{
              color: purchaseCouponInfo?.benefitTextColor
            }"
          >
            <s-adapter-text
              :min-size="10"
              :lines="isListPageScene ? 1 : 2"
              :text="displayInfo.couponTitle"
            />
          </div>
          <s-adapter-text
            class="purchase-coupon-banner__coupon-threshold"
            :min-size="8"
            :text="displayInfo.couponSubTitle"
            :style="{
              color: purchaseCouponInfo.benefitTextColor
            }"
          />
        </div>
        <PurchaseCouponFace 
          v-if="!purchaseCouponInfo?.borderColor"
          class="purchase-coupon-banner__coupon-face"
        />
      </div>
      <!-- 券面信息 END -->

      <!-- 当前凑单状态信息 START -->
      <div class="purchase-coupon-banner__current-status-wrapper">
        <SuiIconSelected
          v-if="isAtTopLevelCoupon"
          class="purchase-coupon-banner__current-status-at-end-icon"
          :style="{
            color: purchaseCouponInfo?.benefitTextColor
          }"
        />

        <!-- 首页场景 START -->
        <SPopover
          v-if="isHomePageScene"
          v-model="isTipsPopoverShow"
          :placemen="tipsPopoverPlacement"
          theme="dark"
          show-close-icon
          outside-close
          fix-disappear-position
          :prop-style="tipsPopoverPropsStyle"
          :custom-class="`purchase-coupon-banner__current-status-info-tips-popover placement-${tipsPopoverPlacement}`"
        >
          <span>
            {{ displayInfo.tipsContent }}
          </span>

          <template #reference>
            <div 
              class="purchase-coupon-banner__current-status"
              :class="{
                'with-at-end-icon': isAtTopLevelCoupon,
              }"
            >
              <!-- 当前状态文字信息 START -->
              <div 
                class="purchase-coupon-banner__current-status-info"
                :class="{
                  'is-coupon-countdown-show': isCouponCountdownShow,
                }"
                :style="{
                  color: purchaseCouponInfo?.benefitTextColor || '#4B0303',
                }"
              >
                <div class="purchase-coupon-banner__current-status-info-popover-ref">
                  <span
                    ref="purchaseCouponBannerInfoTextRef"
                    v-on-visible="updateActionLabelShow"
                    class="purchase-coupon-banner__current-status-info-text"
                    :class="{
                      'is-at-top-level': isAtTopLevelCoupon,
                      'is-count-down-show': isCouponCountdownShow,
                    }"
                    v-html="displayInfo.currentStatus"
                  ></span>
                  <SuiIconDoubt
                    class="purchase-coupon-banner__current-status-info-tips-icon"
                    :style="{
                      color: purchaseCouponInfo?.describeIconColor || '#BBBBBB',
                    }"
                  />
                </div>
              </div>
              <!-- 当前状态文字信息 END -->

              <!-- 券有效期倒计时 START -->
              <PurchaseCouponBannerCountDown
                v-if="isCouponCountdownShow"
                :usingScene="usingScene"
                :countDownLabel="displayInfo.countDownLabel"
                :expiresIn="expiresIn"
                :meta-data="purchaseCouponInfo"
              />
              <!-- 券有效期倒计时 END -->


              <!-- 凑单进度条 START -->
              <PurchaseCouponBannerProgress
                :isShowProgress="isShowProgress"
                :displayInfo="displayInfo"
                :meta-data="purchaseCouponInfo"
              />
              <!-- 凑单进度条 END -->
            </div>
          </template>
        </SPopover>
        <!-- 首页场景 END -->

        <!-- 列表场景 START -->
        <div
          v-else-if="isListPageScene"
          class="purchase-coupon-banner__current-status-info"
        >
          <div 
            class="purchase-coupon-banner__current-status"
            :class="{
              'with-at-end-icon': isAtTopLevelCoupon,
            }"
          >
            <span
              v-on-visible="(el) => mayResetInfoTextFontSizeForListScene(el, {
                oneLineHeight: 13,
                forCount: 2,
              })"
              class="purchase-coupon-banner__current-status-info-text"
              v-html="displayInfo.currentStatus"
            ></span>

            <!-- 凑单进度条 START -->
            <PurchaseCouponBannerProgress
              :isShowProgress="isShowProgress"
              :meta-data="purchaseCouponInfo"
              :displayInfo="displayInfo"
            />
            <!-- 凑单进度条 END -->
            <!-- 券有效期倒计时 START -->
            <PurchaseCouponBannerCountDown
              v-if="isCouponCountdownShow"
              :usingScene="usingScene"
              :countDownLabel="displayInfo.countDownLabel"
              :expiresIn="expiresIn"
              :meta-data="purchaseCouponInfo"
            />
            <!-- 券有效期倒计时 END -->
          </div>
        </div>
        <!-- 列表场景 END -->
      </div>
      <!-- 当前凑单状态信息 END -->

      <!-- 操作按钮 START -->
      <div
        class="purchase-coupon-banner__view-action"
        :style="{
          color: purchaseCouponInfo.benefitTextColor,
          opacity: purchaseCouponInfo.benefitTextColor ? 0.6 : 1
        }"
        @click="onClickAction"
      >
        <span
          v-show="isActionLabelShow"
          class="purchase-coupon-banner__view-action-text"
        >{{ actionBtnText }}</span>

        <SuiIconMoreRight
          class="purchase-coupon-banner__view-action-icon"
          :width="String(isListPageScene ? 12 : 16)"
          :height="String(isListPageScene ? 12 : 16)"
        />
      </div>
      <!-- 操作按钮 END -->
    </div>
  </div>
</template>

<style lang="less">
.flex-row() {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.relative-center() {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.coupon-info-highlight-preset() {
  .coupon-min-threshold,
  .coupon-discount-amount,
  .coupon-saved-amount,
  .coupon-threshold-difference {
    font-weight: 700;
  }
  .coupon-discount-amount,
  .coupon-saved-amount {
    color: #FA6338;
  }
}

.purchase-coupon-banner__view-container {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  font-family: "SF UI Text", system-ui;
  
  .purchase-coupon-banner__current-status-wrapper {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }

  .purchase-coupon-banner__current-status {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    &.with-at-end-icon {
      margin-left: 0;
    }
  }

  .purchase-coupon-banner__current-status-at-end-icon {
    margin-left: -6px;
    width: 16px;
    height: 16px;
  }

  .purchase-coupon-banner__current-status-info {
    display: flex;
    align-items: center;
    font-size: 13px;

    &-tips-icon {
      margin-left: 2px;
      color: rgba(0, 0, 0, 0.30);
    }
  }

  .purchase-coupon-banner__current-status-info-text {
    display: -webkit-box;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    &.is-count-down-show {
      -webkit-line-clamp: 1;
    }
  }

  .purchase-coupon-banner__view-action {
    .flex-row();
    align-items: center;
    height: 100%;
    margin-left: auto;
    &-text {
      font-size: 11px;
    }
  }

  .purchase-coupon-banner__view-action {
    color: rgba(75, 3, 3, 0.60);
  }

  .purchase-coupon-banner__coupon-discount {
    .sui-adapter-text {
      line-height: 1;
      -webkit-box-pack: center;
      -webkit-box-align: center;
      border: none;
    }
  }

  // 首页场景
  &.homepage-scene {
    padding: 0 6px 6px;
    background: transparent;
    height: auto;

    .purchase-coupon-banner__view-card {
      .flex-row();
      height: 48px;
      background: #FFF5F2;
      border-radius: 3px;
      padding: 0 6px 0 4px;
      width: 100%;
      border: 0.5px solid rgba(255, 84, 10, 0.08);
      .purchase-coupon-banner__cover-small {
        position: absolute;
        left: 50%;
        transform: translate(-50%,0);
        // background: #ccc;
        top: 7px;
        width: 80%;
        height: 80%;
        border-radius: 2px;
        border: var(--border-color);
      }
      .purchase-coupon-banner__coupon-bg {
        position: absolute;
        border-radius: 2px;
        top: calc(50% - 1px);
        left: 0;
        transform: translateY(-50%);
        z-index: 3;
        height: 35px;
        width: calc(100%);
        mask: radial-gradient(circle 10px at 0% 50%, transparent 50%, black 50%) left,
        radial-gradient(circle 10px at 100% 50%, transparent 50%, black 50%) right;
        mask-composite: intersect; //#3f0aff
        border: var(--border-color);
        
          &::before, &::after {
            content: "";
            position: absolute;
            background-clip: padding-box;
            //width: 10px; /* 半圆的直径 */
            //height: 10px; /* 半圆的直径 */
            ///*background-color: #FFEB3B; !* 与 body 背景颜色相同 *!*/
            //border: 1px solid #000; /* 给半圆设置边框 */
            //border-radius: 50%; /* 圆形 */
            top: 50%;
            transform: translateY(-50%);
            z-index: 3; /* 保证半圆在最上方 */
            width: 6px;
            height: 12px;
          }
  
          &::before {
            left: -1px; /* 左半圆移出边界 */
            border-radius: 0 6px 6px 0;
            border: var(--border-color);
            border-left: none;
            background-color: var(--border-color);
          }
  
          &::after {
            right: -1px; /* 右半圆移出边界 */
            border-radius: 6px 0 0 6px;
            border: var(--border-color);
            border-right: none;
            background-color: var(--border-color);
          }
      }
      
      //.bottom-border {
      //   position: absolute;
      //   left: 50%;
      //   top: 23px;
      //   transform: translateX(-50%);
      //   width: 82%;
      //   height: 20px;
      //   border-radius: 3px;
      //   border-bottom: 1px solid #f40;
      //  
      //   transform: scaleY(0.5);
      //  // border-bottom: 0.5px solid #f40;
      //   &::before {
      //  content: '';
      //  position: absolute;
      //  left: 0;
      //  top: 0;
      //  height: 10px; /* 左边的边框的完全高度 */
      //  width: 2px; /* 2px宽的边框 */
      //  background-color: black; /* 边框颜色 */
      //  z-index: 1;
      //}
      //
      //  &::after {
      //    content: '';
      //    position: absolute;
      //    left: 0;
      //    top: 10px;
      //    height: calc(100% - 10px); /* 补全剩余部分的背景颜色 */
      //    width: 2px;
      //    background-color: transparent;
      //    z-index: 1;
      //  }
      //}
    }
    .purchase-coupon-banner__coupon-face-group {
      min-width: 100px;
      width: 104px;
      height: 100%;
      position: relative;
    }
    .purchase-coupon-banner__coupon-face {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
    }
    .purchase-coupon-banner__coupon-face-top,
    .purchase-coupon-banner__coupon-face-bottom {
      transform: scale(1.02) translateY(1px);
    }
    .purchase-coupon-banner__coupon-face-info {
      height: 100%;
      max-width: 80%;
      left: 50%;
      top: 50%;
      z-index: 3;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transform: translate(-50%, calc(-50% - 1px));
    }
    .purchase-coupon-banner__coupon-discount {
      text-align: center;
      font-size: 14px;
      font-weight: 900;
      color: #4B0303;
      width: 100%;
      max-height: 20px;
      border: none;
      margin-top: 2px;

      .sui-adapter-text {
        max-height: 24px;
      }
    }
    .purchase-coupon-banner__coupon-threshold {
      margin-top: 2px;
      max-width: 100px;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      color: #4B0303;
      font-weight: 400;
      font-size: 10px;
      line-height: 1;
    }
    .purchase-coupon-banner__current-status-at-end-icon {
      margin-right: 4px;
      color: #4b0303;
    }
    .purchase-coupon-banner__view-action {
      margin-left: auto;
    }
    .purchase-coupon-banner__current-status {
      margin-right: 10px;

      &.with-at-end-icon {
        .purchase-coupon-banner__current-status-info {
          margin-bottom: 0;
        }
      }
    }
    .purchase-coupon-banner__current-status-info {
      color: #4B0303;
      font-weight: 500;
      margin-bottom: 2px;

      &.is-coupon-countdown-show {
        margin-bottom: 3px;
      }
    }
    .purchase-coupon-banner__current-status-info-popover-ref {
      display: -webkit-box;
      overflow: hidden;
      word-break: break-word;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      -webkit-box-pack: center;
      -webkit-box-align: center;
      line-height: 16px;
    }
    .purchase-coupon-banner__current-status-info-tips-icon {
      transform: translateY(-1px);
    }
    .purchase-coupon-banner__current-status-info-text {
      display: inline;

      &.is-at-top-level {
        max-height: 18px;
        -webkit-line-clamp: 1;
      }
    }

    .coupon-info-highlight-preset();
  }

  // 列表页场景
  &.list-scene {
    height: 32px;
    padding: 0;
    background: #fff;

    .purchase-coupon-banner__view-card {
      .flex-row();
      width: 100%;
      height: 100%;
      background: #FFF3D3;
      padding: 0 6px;
    }
    .purchase-coupon-banner__coupon-face-group {
      min-width: 80px;
      width: 80px;
      height: 28px;
      position: relative;
    }
    .purchase-coupon-banner__coupon-face {
      top: 50%;
      left: 50%;
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      transform-origin: top center;
      transform: translate(-50%, -50%);
    }
    .purchase-coupon-banner__coupon-face-info {
      z-index: 3;
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
    }
    .purchase-coupon-banner__coupon-discount {
      white-space: nowrap;
      font-size: 13px;
      font-weight: 800;
      width: 66px;
      max-height: 16px;
      text-align: center;
      color: #4B0303;
      margin-top: 2px;
      
      .sui-adapter-text {
        min-height: 13px;
        line-height: 13px;
      }
    }
    .purchase-coupon-banner__coupon-threshold {
      max-width: 62px;
      white-space: nowrap;
      text-align: center;
      font-size: 9px;
      line-height: 1;
      color: #4B0303;
      margin-top: 1px;
    }
    .purchase-coupon-banner__current-status-at-end-icon {
      margin-right: 2px;
      color: #4B0303;
    }
    .purchase-coupon-banner__current-status {
      font-size: 11px;
      line-height: 1;
      margin-right: 10px;
    }
    .purchase-coupon-banner__current-status-info {
      color: #4B0303;
      font-size: 12px;
    }
    .purchase-coupon-banner__current-status-info-text {
      line-height: 1;
      max-height: 26px;
    }
    .purchase-coupon-banner__current-status-progress {
      margin-top: 1px;
    }
    .purchase-coupon-banner__view-action-icon {
      width: 12px;
      height: 12px;
    }

    .coupon-info-highlight-preset();
  }
}

.purchase-coupon-banner__current-status-info-tips-popover{
  .sui-popover__content-arrow {
    // 三角形向下圆角
    border-bottom-right-radius: 1px;
    // 三角形向上圆角
    border-top-left-radius: 1px;
  }
  .sui-popover__close-arrow {
    font-size: 18px;
    color: #c1c1c1;
    svg path {
      stroke-width: 0.6;
    }
  }
  .sui-popover__content-body {
    padding: 8px 12px 8px 8px;
  }
}

[mir="rtl"] .purchase-coupon-banner__view-action-icon {
  transform: rotateY(180deg);
}
</style>
