<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'PurchaseCouponBannerCouponFace',
  props: {
    borderColor: {
      type: String,
      default: '#FF540A'
    }
  },
})
</script>

<template>
  <svg
    width="80"
    height="28"
    viewBox="0 0 80 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
<!--    <path-->
<!--      d="M7 14C7 12.8954 7.89543 12 9 12H71C72.1046 12 73 12.8954 73 14V26C73 27.1046 72.1046 28 71 28H9C7.89543 28 7 27.1046 7 26V14Z"-->
<!--      fill="url(#paint0_linear_232_50143)"-->
<!--    />-->
    <path
      d="M7.25 14C7.25 13.0335 8.0335 12.25 9 12.25H71C71.9665 12.25 72.75 13.0335 72.75 14V26C72.75 26.9665 71.9665 27.75 71 27.75H9C8.0335 27.75 7.25 26.9665 7.25 26V14Z"
      :stroke="borderColor"
      stroke-opacity="0.3"
      stroke-width="0.5"
      transform="translate(0, 2)"
    />
    <g filter="url(#filter0_d_232_50143)">
      <mask
        id="path-3-inside-1_232_50143"
        fill="white"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1 2C1 0.89543 1.89543 0 3 0H77C78.1046 0 79 0.895431 79 2V9C76.7909 9 75 10.7909 75 13C75 15.2091 76.7909 17 79 17V24C79 25.1046 78.1046 26 77 26H3C1.89543 26 1 25.1046 1 24V17C3.20914 17 5 15.2091 5 13C5 10.7909 3.20914 9 1 9V2Z"
        />
      </mask>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1 2C1 0.89543 1.89543 0 3 0H77C78.1046 0 79 0.895431 79 2V9C76.7909 9 75 10.7909 75 13C75 15.2091 76.7909 17 79 17V24C79 25.1046 78.1046 26 77 26H3C1.89543 26 1 25.1046 1 24V17C3.20914 17 5 15.2091 5 13C5 10.7909 3.20914 9 1 9V2Z"
        fill="url(#paint1_linear_232_50143)"
      />
      <path
        d="M79 9V9.5H79.5V9H79ZM79 17H79.5V16.5H79V17ZM1 17V16.5H0.5V17H1ZM1 9H0.5V9.5H1V9ZM3 -0.5C1.61929 -0.5 0.5 0.619288 0.5 2H1.5C1.5 1.17157 2.17157 0.5 3 0.5V-0.5ZM77 -0.5H3V0.5H77V-0.5ZM79.5 2C79.5 0.619288 78.3807 -0.5 77 -0.5V0.5C77.8284 0.5 78.5 1.17157 78.5 2H79.5ZM79.5 9V2H78.5V9H79.5ZM75.5 13C75.5 11.067 77.067 9.5 79 9.5V8.5C76.5147 8.5 74.5 10.5147 74.5 13H75.5ZM79 16.5C77.067 16.5 75.5 14.933 75.5 13H74.5C74.5 15.4853 76.5147 17.5 79 17.5V16.5ZM79.5 24V17H78.5V24H79.5ZM77 26.5C78.3807 26.5 79.5 25.3807 79.5 24H78.5C78.5 24.8284 77.8284 25.5 77 25.5V26.5ZM3 26.5H77V25.5H3V26.5ZM0.5 24C0.5 25.3807 1.61929 26.5 3 26.5V25.5C2.17157 25.5 1.5 24.8284 1.5 24H0.5ZM0.5 17V24H1.5V17H0.5ZM4.5 13C4.5 14.933 2.933 16.5 1 16.5V17.5C3.48528 17.5 5.5 15.4853 5.5 13H4.5ZM1 9.5C2.933 9.5 4.5 11.067 4.5 13H5.5C5.5 10.5147 3.48528 8.5 1 8.5V9.5ZM0.5 2V9H1.5V2H0.5Z"
        :fill="borderColor"
        fill-opacity="0.3"
        mask="url(#path-3-inside-1_232_50143)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_232_50143"
        x="0"
        y="0"
        width="80"
        height="28"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="0.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.597614 0 0 0 0 0.55002 0 0 0 0 0.449347 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_232_50143"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_232_50143"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_232_50143"
        x1="13.0112"
        y1="13.0488"
        x2="59.8936"
        y2="49.5113"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0.0271138"
          stop-color="white"
        />
        <stop
          offset="0.425"
          stop-color="#FFF8ED"
        />
        <stop
          offset="1"
          stop-color="white"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_232_50143"
        x1="10.929"
        y1="-0.320309"
        x2="104.049"
        y2="42.3252"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0.0271138"
          stop-color="#FFFEFE"
        />
        <stop
          offset="0.502488"
          stop-color="#FFFAEB"
        />
        <stop
          offset="1"
          stop-color="white"
        />
      </linearGradient>
    </defs>
  </svg>
</template>
