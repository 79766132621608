<script>
import { defineComponent } from 'vue'
import { hexToRGBA } from 'public/src/pages/components/ccc/common/utils.js'

export default defineComponent({
  name: 'PurchaseCouponBannerCountDown',
  props: {
    usingScene: {
      type: String,
      default: 'homepage', // homepage 首页 | list 列表
    },
    countDownLabel: {
      type: String,
      default: '',
    },
    expiresIn: {
      type: [Array, null], // [hours, minutes, seconds]
      default: () => [],
    },
    metaData: {
      type: Object,
      default: () => ({}),
    }
  },
  methods: {
    hexToRGBA,
  },
})
</script>

<template>
  <div 
    class="purchase-coupon-banner__count-down-widget"
    :class="{
      'homepage-scene': usingScene === 'homepage',
      'list-scene': usingScene === 'list',
    }"
    :style="{
      color: metaData?.benefitTextColor ? hexToRGBA(metaData?.benefitTextColor, 0.8) : ''
    }"
  >
    <span 
      class="purchase-coupon-banner__count-down-widget-label"
    >
      <!-- {{ countDownLabel }} -->
      
      <!-- MOCK -->
      <span>Expires in</span>
    </span>

    <div 
      class="purchase-coupon-banner__count-down-widget-unit"
      :style="{
        background: metaData?.benefitTextColor ? hexToRGBA(metaData?.benefitTextColor, 0.8) : '',
        color: metaData?.backgroundColor
      }"
    >
      {{ expiresIn[0] }}
    </div>
    <span>:</span>
    <div 
      class="purchase-coupon-banner__count-down-widget-unit"
      :style="{
        background: metaData?.benefitTextColor ? hexToRGBA(metaData?.benefitTextColor, 0.8) : '',
        color: metaData?.backgroundColor
      }"
    >
      {{ expiresIn[1] }}
    </div>
    <span>:</span>
    <div 
      class="purchase-coupon-banner__count-down-widget-unit"
      :style="{
        background: metaData?.benefitTextColor ? hexToRGBA(metaData?.benefitTextColor, 0.8) : '',
        color: metaData?.backgroundColor,
      }"
    >
      {{ expiresIn[2] }}
    </div>
  </div>
</template>
 
<style lang="less" scoped>
.purchase-coupon-banner__count-down-widget {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &.homepage-scene {
    color: rgba(75, 3, 3, 0.80);

    .purchase-coupon-banner__count-down-widget-unit {
      line-height: 1;
      font-size: 11px;
      background-color: #4B0303CC;
      color: #fff;
      padding: 2px;
      border-radius: 2px;
    }
  }

  &.list-scene {
    color: #000000A6;
  }

  &-label {
    margin-right: 4px;
  }
}
</style>
